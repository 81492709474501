import { useFormContext } from 'react-hook-form';

import { useRouter } from 'next/router';

import { Box, Button, Logo, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

import { useLeadSubmissionContext } from '../../../contexts/lead-submission';
import { Container, Content } from '../../layout';

const SendEmailIllustration = () => (
  <svg
    width="125"
    height="106"
    viewBox="0 0 125 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52 106C80.7188 106 104 82.7188 104 54C104 25.2812 80.7188 2 52 2C23.2812 2 0 25.2812 0 54C0 82.7188 23.2812 106 52 106Z"
      fill="#E9F6FF"
    />
    <g filter="url(#filter0_d_1721_78622)">
      <rect
        x="25"
        y="27"
        width="54"
        height="54"
        rx="18"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M35.4999 25.965C32.2746 26.4162 30.9251 30.5105 33.2639 32.7897L46.8664 46.0912L42.0807 58.0938C41.2422 60.1969 42.2633 62.5701 44.3678 63.4092C45.4957 63.8589 46.7646 63.7858 47.8329 63.2018L58.4838 57.443L70.4992 69.1824C72.3307 70.9765 75.4515 70.551 76.7477 68.3476L103.462 22.9788C104.243 21.6609 104.192 19.9977 103.337 18.7277C102.483 17.4578 100.976 16.7761 99.449 16.9888L35.4999 25.965ZM40.3762 31.3432L89.1211 24.5059L52.2193 42.8389L52.3125 43.0107L40.3762 31.3432ZM72.8663 63.1102L56.7155 47.3115L92.6953 29.4322L72.8663 63.1102Z"
        fill="#8BD1FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1721_78622"
        x="5"
        y="0.950195"
        width="119.015"
        height="104.05"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.423529 0 0 0 0 0.505882 0 0 0 0 0.670588 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1721_78622"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1721_78622"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const AuthEmailHeader = () => {
  const router = useRouter();

  const { logLeadSubmissionEvent, coreEventData } = useLeadSubmissionContext();
  const { isMobile } = useScreenSize();

  const onResetFlowClick = () => {
    logLeadSubmissionEvent({
      event_action: 'submit_client',
      event_payload: { ...coreEventData },
    });
    window.location.href = window.location.pathname;
  };

  const onSignInClick = () => {
    logLeadSubmissionEvent({
      event_action: 'sign_in',
      event_payload: { ...coreEventData },
    });
    router.push('/auth/signin');
  };

  return (
    <Box
      p={{ xs: '24px', md: '12px 80px' }}
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="999"
      borderBottom={{ xs: 'none', md: '1px solid #DBDFE6' }}
    >
      <Box onClick={onResetFlowClick} cursor="pointer">
        <Logo
          width={{ xs: 'auto', md: '160px' }}
          variant={isMobile ? 'iconOnly' : 'horizontal'}
        />
      </Box>
      <Box gap="16px">
        {!isMobile && (
          <Button
            onClick={onResetFlowClick}
            color="secondary"
            size="large"
            slim
          >
            Submit client
          </Button>
        )}
        <Button
          onClick={onSignInClick}
          size={isMobile ? undefined : 'large'}
          slim={!isMobile}
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
};

export const AuthEmail = () => {
  const { watch } = useFormContext();

  const { loan_officer_email, agent_email } = watch();
  const email = loan_officer_email || agent_email;

  return (
    <>
      <Content height={{ xs: '100vh', md: 'auto' }} p={{ md: '0px 80px' }}>
        <AuthEmailHeader />

        <Container>
          <Box flex="1" flexDirection="column" justifyContent="center">
            <Box flexDirection="column" alignItems="center" gap="16px">
              <SendEmailIllustration />
              <Paragraph variant="heading-3" textAlign="center">
                Check your email
              </Paragraph>
              <Paragraph textAlign="center">
                We’ve sent you a confirmation email to <strong>{email}</strong>.
              </Paragraph>
              <Paragraph textAlign="center">
                Click the link in the email to view your transaction.
              </Paragraph>
            </Box>

            {/* TODO: enable the code block below when "send again" is implemented */}
            {/* <Box
              pb={{ xs: '40px', md: '90px' }}
              flexDirection="column"
              alignItems="center"
              gap="16px"
            >
              <Paragraph textAlign="center">Didn’t get the email?</Paragraph>
              {isDesktop && (
                <>
                  <Paragraph textAlign="center">
                    Check your spam folder or{' '}
                  </Paragraph>
                  <Paragraph
                    as="span"
                    textAlign="center"
                    color="#1192E5"
                    cursor="pointer"
                    onClick={() => {
                      // const { success } = sendEmail()
                      // if (success)
                      toast('Email successfully sent', {
                        type: 'success',
                        position: 'bottom-center',
                      });
                    }}
                  >
                    Send again
                  </Paragraph>
                </>
              )}
              {!isDesktop && (
                <>
                  <Paragraph textAlign="center">
                    Check your spam folder or{' '}
                    <Paragraph
                      as="span"
                      textAlign="center"
                      color="#1192E5"
                      cursor="pointer"
                      onClick={() => {
                        // const { success } = sendEmail()
                        // if (success)
                        toast('Email successfully sent', {
                          type: 'success',
                          position: 'bottom-center',
                        });
                      }}
                    >
                      Send again
                    </Paragraph>
                  </Paragraph>
                </>
              )}
            </Box> */}
          </Box>
        </Container>
      </Content>
    </>
  );
};
